var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: { bottom: "" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function (ref) {
              var on = ref.on
              var attrs = ref.attrs
              return [
                _c(
                  "span",
                  _vm._g(_vm._b({}, "span", attrs, false), on),
                  [_vm._t("default")],
                  2
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
    },
    [_c("span", [_vm._v(_vm._s(_vm.text))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }