<template>
    <v-tooltip bottom>
     <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <slot></slot>
      </span>
     </template>
      <span >{{text}}</span>
    </v-tooltip>
</template>

<script>
export default {
name:"sx-tooltip",

props:["text"]
}
</script>

<style>

</style>